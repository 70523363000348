/* tslint:disable:no-string-literal variable-name */
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {UntypedFormControl} from '@angular/forms';
import {GetDescriptionDialogComponent, GetDescriptionDialogData} from './forms/get-description-dialog/get-description-dialog.component';

import {Router} from '@angular/router';
import {IFile} from './forms/files/files.component';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';



@Injectable()
export class MainService {



  get server() {
    // return "https://api.vernissage.life"
    if (document.location.hostname === 'localhost' ||  document.location.hostname === 'stuff.vernissage.life') {
      return this.debubLocalServer;
    } else if (document.location.hostname.startsWith('192.168.0.')) {
      const srv = 'http://' + document.location.hostname + ':8000';
      // alert(srv);
      return srv;
    } else if (document.location.host === 'localhost:4200' || document.location.host === 'localhost:4300' ) {
      return this.debug_server;
    } else {
      let  api_host = window['api_host'];
      if ( !api_host){
        api_host = 'http://api.vernissage.life';
      }
      return api_host;
    }
  }

  get fastOssLinkAddress() {
    // @ts-ignore
    return window.fastOssLinkAddress
      ?? 'https://oss.vernissage.life';
  }

  get allowShortLinks() {
    if (document.location.host === 'localhost:4200') {
      return true;
    } else {
      return window['allowShortLinks'] ? window['allowShortLinks'] : false;
    }
  }

  constructor(private http: HttpClient, private router: Router, public dialog: MatDialog, private snackBar: MatSnackBar) {
    if ( undefined === window['api_host']) {
      window['api_host'] = 'https://api.vernissage.life';
    }
    console.log('host.js=' + window['api_host']);
    this.httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})

    };


  }

  public debug_server = 'http://localhost:8000';
  public battle_server = 'https://api.vernissage.life';
  private debugServer = 'http://192.168.0.110:8000';
  private debubLocalServer = 'http://localhost:8000';
  IsMenuOpened = false;
  showPhones = false;
  selectedStatusesSection = [];
  filter_is_tsn_member = false;
  filter_showPhones = false;
  monthes = [
    '',
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря',

  ];
  public statuses: any[] = [];
  public statusesDict = {};
  public userConfig = new UserConfig();
  public selectedOss: number;
  public username = '';
  public currentHeader = 'Главная';
  public currentMatIcon = 'home';

  selectedSection = 0;
  allSections: any[];
  public entry_count: number;
  public found_count: number;
  public searchValue = '';

  public is_tsn_member = false;
  public length = 0;
  public offset = 0;
  public pagesize = 50;
  public pageIndex = 0;
  public order = 'apartment';
  public residents_list: any[] = [];
  public group_type = 2;
  public searchvalue = new UntypedFormControl('');
  public httpOptions: any;

  ossItems: any[];

  // getDate(value: string) {
  //   const d = new Date(value);
  //   return  ('' + d.getHours()).padStart(2, '0') + ':' + ('' + d.getMinutes()).padStart(2, '0') + '   ' +
  //     ('' + d.getDay()).padStart(2, '0') + '/' + ('' + d.getMonth()).padStart(2, '0') + '/' + (d.getFullYear() - 2000);
  // }

  allowStatus(item_status: string, for_status: string) {
    // console.log(item_status);
    // console.log(this.statusesDict[for_status]['allow_statuses']);
    const st = this.statusesDict[for_status]['allow_statuses'];
    if (st.indexOf(item_status) > -1) {
      // console.log("allow");
      return true;
    } else {
      // console.log("NO")
      return false;
    }
  }


  onLoggedIn() {
    console.log('onLoggedIn');
    this.getUserConfig();
    this.get_oss_list();
    this.get_statuses_list();
  }


  public selectOSS($event) {
    this.saveUserConfig();
  }


  public getUserConfig() {
    this.http.get(this.server + '/stuff/getconfig', this.httpOptions).subscribe(
      data => {
        let d = data['config'];

        // (<Result> Object.assign(new Result(), d));
        // alert(d)
        // console.log(d);
        d = JSON.parse(d);
        // console.log(this.userConfig);
        if (d.constructor.name === 'string') {
          this.userConfig = JSON.parse(d);
        } else {
          this.userConfig = d;
        }
        // console.log(this.userConfig);
        this.selectedOss = this.userConfig.selectedOss;


      },
      err => {

      }
    );
  }

  public saveUserConfig() {
    this.http.post(this.server + '/stuff/setconfig', JSON.stringify(this.userConfig), this.httpOptions).subscribe(
      data => {

      },
      err => {

      }
    );
  }

  public setOssHeader(value) {
    if (this.httpOptions.headers.has('x-oss-id')) {
      this.httpOptions.headers.delete('x-os-id');
    }
    this.httpOptions.headers.append('x-oss-id', '' + value);
  }

  onLoggedOut() {
    this.ossItems = null;
  }

  get_oss_list() {

    this.http.get(this.server + '/stuff/oss', this.httpOptions).subscribe(
      data => {
        // alert(data)
        this.ossItems = data['data']; // JSON.parse(data.toString());
      },
      err => {

      }
    );
  }

  get_statuses_list() {

    this.http.get(this.server + '/stuff/status', this.httpOptions).subscribe(
      data => {
        // alert(data)
        this.statuses = data['data']; // JSON.parse(data.toString());
        for (const s of this.statuses) {
          this.statusesDict[s['key']] = s;
        }

      },
      err => {

      }
    );
  }

  formatPhone(content: string) {
    try {
      if (!content) { return ''; }
      const match = content.match(/(\+?[\d-\(\)]{11,15})/);
      const prefixStart = 0;
      const phoneStart = match.index;
      const phonesLength = match[1].length;
      const postfixStart = phoneStart + phonesLength;
      const endPos = content.length;
      const phone = match[1];
      const result = `${content.slice(prefixStart, phoneStart)} <a href="tel:${phone}" click="event.stopPropagation()">${phone}</a> ${content.slice(postfixStart, endPos)}`;


      return result;
    } catch (e) {
      return content;

    }
  }

  downloadFile(editFile: any[]) {

    this.http.get(this.server + '/stuff/files/get-file?id=' + editFile[0], { responseType: 'blob', }
    ).subscribe(
      data => {
        const blob = data; // .split(',',2);

        const obj = URL.createObjectURL(blob); //
        const a = document.createElement('a');
        a.setAttribute('href', obj);
        a.hidden = true;
        a.setAttribute('target', '_self');
        a.setAttribute('download', editFile[1]);
        a.innerText = 'FILE';
        document.body.appendChild(a);
        a.click();

      },
      err => {

      });

  }
  downloadFile_v2(file: IFile) {
    // TODO: Если файл имеет уровень доступа выше дозволенного, нужно вернуть ошибку 403.
    this.http.get(this.server + '/files/get?guid=' + file.guid, { responseType: 'blob', }
    ).subscribe(
      data => {
        const blob = data; // .split(',',2);

        const obj = URL.createObjectURL(blob); //
        const a = document.createElement('a');
        a.setAttribute('href', obj);
        a.hidden = true;
        a.setAttribute('target', '_self');
        a.setAttribute('download', file.name);
        a.innerText = 'FILE';
        document.body.appendChild(a);
        a.click();

      },
      err => {

      });

  }

  deleteFile_v2(file: IFile) {

    this.http.delete(this.server + '/files/delete?guid=' + file.guid
    ).subscribe(
      data => {


      },
      err => {

      });

  }

  changeFile_v2(file: IFile) {

    this.http.put(this.server + '/files/change', file
    ).subscribe(
      data => {


      },
      err => {

      });

  }

  formatMail(content: string) {
    try {
      const match = content.match(/([a-zA-Z\-\._0-9]+@[0-9a-zA-Z\-\._]+.[a-zA-Z\-_0-9]+)/);
      const prefixStart = 0;
      const phoneStart = match.index;
      const phonesLength = match[1].length;
      const postfixStart = phoneStart + phonesLength;
      const endPos = content.length;
      const phone = match[1];
      const res = `${content.slice(prefixStart, phoneStart)} <a href='mailto:${phone}'  click='event.stopPropagation()'>${phone}</a> ${content.slice(postfixStart, endPos)}`;

      return res;
    } catch (e) {
      return content;

    }
  }

  getLocation(href) {
    const l = document.createElement('a');
    l.href = href;
    return l;
  }
  formatLink(content: string, only_icon: boolean) {
    try {
      const match = content.match(/([a-zA-Z-.\/:_]+\.[0-9a-zA-Z-_]+\/[^ ]+)/);
      const prefixStart = 0;
      const phoneStart = match.index;
      const phonesLength = match[1].length;
      const postfixStart = phoneStart + phonesLength;
      const endPos = content.length;
      const link = match[1];
      const l = document.createElement('a');
      l.href = link;
      const favicon = `${l.origin}/favicon.ico`;
      let res = '';
      if (only_icon) {
        res = `<img src="${favicon}" height="18px"/>`;
      } else {
        res = `${content.slice(prefixStart, phoneStart)}<img src='${favicon}' height="18px"/> <a href='${link}'  click='event.stopPropagation()'>${link}</a> ${content.slice(postfixStart, endPos)}`;
      }

      return res;
    } catch (e) {
      return content;

    }
  }

  getPhone(content: string) {
    try {
      const match = content.match(/(\+?[\d-\(\) ]{11,15})/);
      return match[1];
    } catch (e) {
      return '#';

    }
  }

  getMail(content: string) {
    try {
      const match = content.match(/([0-9a-zA-Z-._]+@[a-zA-Z-._]+.[a-zA-Z-_]+)/);

      return match[1];
    } catch (e) {
      return '#';

    }
  }

  getLink(content: string) {
    try {
      const match = content.match(/([a-zA-Z-.\/:_]+\.[0-9a-zA-Z-_]+\/[^ ]+)/);
      return match[1];
    } catch (e) {
      return '#';

    }

  }

  navigateTo(url: string, target: string) {
    if (url !== '#') {
      window.open(url, target);
    }
  }

  setStatus(oss_list: any[], status_key: string, return_human_data: boolean, callback: (data: any, oss: any) => void) {
    console.log(status_key);
    console.log(this.statuses);
    let action = '';

    for (const st of this.statuses) {
      if (st.key === status_key) {
        action = st.action;
        break;
      }

    }
    console.log(action);
    const actions = action.split(',');
    const description = '';
    const files = null;
    this.doAction(actions, description, status_key, oss_list, return_human_data, callback);
  }

  _setItemStatus(oss_list: any[], actions, status_key, description, return_human_data, setStatusCallback: (data: any, oss: any) => void){
    const oss = oss_list.shift();
    if (!oss) { return; }
    this._setStatus(oss, status_key, description, return_human_data, (data) => {
      this._setItemStatus(Object.assign([], oss_list), actions, status_key, description, return_human_data, setStatusCallback);
      setStatusCallback(data, oss);
      this.doAction(actions, description, status_key, oss_list, return_human_data, setStatusCallback);
    });
  }

  private doAction(actions, description, status_key: string, oss_list: any[], return_human_data: boolean,
                   setStatusCallback: (data: any, oss: any) => void) {
    if (actions.length === 0) {
      return;
    }
    const act = actions.shift();
    if (act === 'set-status') {
     this._setItemStatus(Object.assign([], oss_list), actions, status_key, description, return_human_data, setStatusCallback);
    } else if (act === 'fill-votes') {
      if (oss_list.length > 0) {
        const oss = oss_list[0];
        this.router.navigate(['/bulletin'], {queryParams: {vote_id: oss.vote_id}});
      }
      return;
    } else if (act === 'get-scan') {

    } else if (act === 'query-description') {

      const dialogData = new GetDescriptionDialogData();
      dialogData.title = 'Дайте пояснения';
      dialogData.isEditBox = true;
      dialogData.buttons = 'Ок|Отмена';
      this.dialog.open(GetDescriptionDialogComponent, {
        data: dialogData
      }).afterClosed().subscribe(result => {
        if (dialogData.result === 'Отмена') {
          return;
        }
        description = dialogData.editValue;
        this.doAction(actions, description, status_key, oss_list, return_human_data, setStatusCallback);
      });

      // description = window.prompt("Дайте пояснения","");
    }


  }
  getItemVote(vote_id: number, callback: (vote_data: any) => void){
    this.http.get(this.server + '/stuff/resident/get-item-vote?vote_id=' + vote_id, this.httpOptions).subscribe(
      data => {
        if (callback) {
          callback(data);
        }
      },
      err => {

      }
    );
  }
  addVoteNote(vote_id: number, message: string, status_key: string, files: any[], callback: (data: any) => void){
    this.http.post(this.server + '/stuff/resident/add-vote-note', {
      vote_id,
      message,
      status_key,
      files
    }, this.httpOptions).subscribe(
      data => {
        this.snackBar.open('Заметка о собрании сохранена', 'Ok', {
          verticalPosition: 'top',
          panelClass: 'ok-panel',
          duration: 5000,
        });
        if (callback) {
         callback(data);
        }
      },
      err => {

      }
    );
  }

  _setStatus( oss: any, status_key: string, description: string, return_human_data: boolean, callback: (data: any) => void) {

    if (!description) {
      description = '';
    }
    let files = [];
    if (oss.newNote && oss.newNote.length >= 6)
    {
      files = oss.newNote[5];
    }
    this.http.post(this.server + '/stuff/resident/set-status?get-human=' + return_human_data, {
      oss_id: oss.oss_id,
      human_id: oss.human_id,
      status_key,
      description,
      files
    }, this.httpOptions).subscribe(
      data => {
        callback(data);
      },
      err => {

      }
    );
  }


  generateBulletin(human_guid: string, oss_guid_list: string[], view: boolean) {

    window.open(this.generateBulletinLink(human_guid, oss_guid_list, view), '_blank');
    // this.http.get(this.server + '/stuff/oss/bulletin?h='+human_guid + oss_guids, { responseType: 'blob'  }).subscribe(
    //   data => {
    //     const url= window.URL.createObjectURL(data);
    //     // data.arrayBuffer().then(value=> {this.pdfSrc = value});
    //     //
    //     // const d = new Blob([data.blob()], { type: 'application/pdf' });
    //     // const fileURL = URL.createObjectURL(d);
    //     window.open(url, '_blank');
    //   },
    //   error => {
    //
    //   }
    // );
  }


  genarateOssLink(human_guid: string, oss_guid_list: any, view: boolean){
    let oss_guids = '';
    for (const oss of oss_guid_list){
      oss_guids += '&o=' + oss;
    }
    const link = this.fastOssLinkAddress + '?h=' + human_guid + oss_guids;
    return link;
  }
  generateBulletinLink(human_guid: string, oss_guid_list: any, view: boolean) {
    let oss_guids = '';
    for (const oss of oss_guid_list){
      oss_guids += '&o=' + oss;
    }
    const link = this.server + '/stuff/oss/bulletin?view=' + view + '&h=' + human_guid + oss_guids;
    return link;
  }

  iosCopyToClipboard(el) {
    const oldContentEditable = el.contentEditable;
    const  oldReadOnly = el.readOnly;
    const  range = document.createRange();

    el.contentEditable = true;
    el.readOnly = false;
    range.selectNodeContents(el);

    const s = window.getSelection();
    s.removeAllRanges();
    s.addRange(range);

    el.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.

    el.contentEditable = oldContentEditable;
    el.readOnly = oldReadOnly;

    document.execCommand('copy');
    // console.log('copied')
  }
}

export class UserConfig {
  public selectedOss: number;
}


