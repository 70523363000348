<div class="dialog">
  <div style="display: flex; flex-direction: row">
    <div mat-dialog-title style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; flex-grow: 1; font-size: x-large;">
<!--      <h2 >-->
        {{human ? human.fio : 'НЕ ИЗВЕСТНО'}}
<!--      </h2>-->
    </div>
    <div>
      <button mat-icon-button style="margin-top: -5px;"
              (click)="dialogRef.close()"><mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <div mat-dialog-content style="padding: 10px 24px 0 24px;">
    <mat-spinner style="margin: auto;" *ngIf="!human"></mat-spinner>
    <div class="content" *ngIf="human">
      <div class="human-area" addUploadDragDrop (onFileDropped)="dropHumanFile($event)">
        <mat-chip-listbox>
          <mat-chip *ngFor="let phone of (phones!=null?phones:[])"
                    (click)="main.navigateTo('tel:'+main.getPhone(phone[4]), '_blank')">
            <mat-icon>phone</mat-icon>
            <span [innerHTML]="main.formatPhone(phone[4])"></span></mat-chip>
          <mat-chip *ngFor="let mail of (mails!=null?mails:[])"
                    (click)="main.navigateTo('mailto:'+main.getMail(mail[4]), '_blank')">
            <mat-icon>mail</mat-icon>
          </mat-chip>
          <mat-chip *ngFor="let link of (links!=null?links:[])" (click)="main.navigateTo(main.getLink(link[4]), '_blank')"><span
            [innerHTML]="main.formatLink(link[4], true)"></span></mat-chip>
        </mat-chip-listbox>
        <div class="human-messages">
          <div class="human-messages-area" *ngIf="notes && notes.length>0">
            <div class="item-message" *ngFor="let note of notes">
              <span
                style="color: gray">{{note[3] | date: 'HH:mm dd/MM/yyyy'}}</span><span> {{note.length > 3 ? note[4] : note}}
              </span>
              <span *ngIf="note[5] && note[5].length > 0">
                <button mat-icon-button color="primary" (click)="$event.stopPropagation(); showFiles(note[5])">
                  <mat-icon [matBadge]="note[5].length" style="font-size: x-large" matBadgeSize="small" matBadgeColor="primary"  >attach_file</mat-icon>
                </button>
              </span>
            </div>
          </div>
          <div class="new-message-editor">
            <div class="new-message-editor-message">
              <mat-form-field class="full-width">
                <textarea matInput style="" [(ngModel)]="newNote[4]" name="new-note-editor"></textarea>
              </mat-form-field>
              <div class="message-files-area" *ngIf="addFiles && addFiles.length>0">
                <ng-container>
                  <div *ngFor="let editFile of addFiles;let i= index " class="item-message-file">
                    <!--          <a *ngIf="editFile[0] != 0" routerLink="." (click)="main.downloadFile(editFile)"  >{{editFile[1]}}</a>-->
                    <span *ngIf="editFile[0] == 0">
                      <a routerLink=".">{{editFile[1]}}</a>
                      <button mat-icon-button color="accent" (click)="$event.stopPropagation(); deleteAttachment(i, addFiles)">
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </span>
                    <mat-icon *ngIf="editFile.length > 2 && editFile[2].length == 0">play_for_work</mat-icon>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="new-message-editor-buttons">
              <div class="new-message-editor-button-attfile">
                <button mat-icon-button matSuffix color="primary" (click)="fileInput.click()">
                  <mat-icon>attach_file</mat-icon>
                </button>
              </div>
              <div class="new-message-editor-button-send">
                <button mat-icon-button matSuffix color="primary" (click)="sendMessage($event, newNote)">
                  <mat-icon style="font-size: 32px;">send</mat-icon>
                </button>
              </div>
            </div>
          </div>


          <input hidden type="file" #fileInput (change)="uploadFile($event.target.files)">
          <!--              </td>-->
          <!--            </tr>-->
          <!--          </table>-->
        </div>
      </div>
      <div class="oss-list">


        <ng-container *ngIf="human && human.oss">
          <ng-container *ngFor="let oss of human.oss">
            <div class="item-oss" addUploadDragDrop (onFileDropped)="dropOssFile(oss, $event)">
              <input hidden type="file"  id="{{'ossfileinput'+oss.id}}" (change)="dropOssFile(oss, $event.target.files)">
              <div class="oss-title-area">
                <span style="font-size: x-large;">{{oss.oss_title}}
                  <mat-icon *ngIf="oss.oss_is_tsn_member"
                            style="vertical-align: sub;font-size: xx-large;">account_box</mat-icon></span>
                <span style="white-space: nowrap; margin-left: 10px;">{{oss.oss_start_time | date: 'HH:mm dd/MM/yyyy' }} - {{oss.oss_end_time  | date: 'HH:mm dd/MM/yyyy'}}</span>
                <!--            <mat-icon style="font-size: 22px;"                      [style.color]="oss.status_icon_color">{{oss.status_icon}}</mat-icon>-->
                <span class="note-status status" [style.color]="oss.status_icon_color">
                <mat-icon style="" [innerText]="oss.status_icon"></mat-icon>
<!--                <span class="status-title">{{oss.status__title}}</span>-->


              </span>
                <button mat-icon-button matSuffix color="primary" (click)="getElementById('ossfileinput'+oss.id).click()">
                  <mat-icon>attach_file</mat-icon>
                </button>
                <button mat-icon-button matSuffix color="primary" (click)="addOssNote(oss)">
                  <mat-icon>note_add</mat-icon>
                </button>
                <!-- TODO: добавить кнопки генерации бюллетеня и ссылки -->
                <app-vote-menu *ngIf="human && oss" [oss]="oss" [human]="human"></app-vote-menu>
<!--                <button mat-button [matMenuTriggerFor]="menu" (click)="generateOssLink(true, oss)"><mat-icon>pan_tool</mat-icon></button>-->
<!--                <mat-menu #menu="matMenu" >-->
<!--                  <button mat-menu-item class="animated"-->
<!--                          [disabled]="!allowVote(oss)" (click)="generateBulletin(true, oss)"-->
<!--                          matTooltip="Сгенерировать и открыть бюллетень (Решение) для выбранного собственника">-->
<!--                    <mat-icon>print</mat-icon> Открыть..-->
<!--                  </button>-->
<!--                  <button mat-menu-item class="animated" [matMenuTriggerFor]="menu2"-->
<!--                          [disabled]="!allowVote(oss)"-->
<!--                          matTooltip="Показать / копировать ссылку на голосовалку">-->
<!--                    <mat-icon>link</mat-icon> Сслыка-->
<!--                  </button>-->

<!--                  <button mat-menu-item class="animated"-->

<!--                          [disabled]="!allowVote(oss)" [routerLink]="['/bulletin']"-->
<!--                          [queryParams]="{human_id: human.human_id, oss_id: oss.oss_id}"-->
<!--                          matTooltip="Заполнить решение">-->
<!--                    <mat-icon>ballot</mat-icon> Заполнить-->
<!--                  </button>-->
<!--                  &lt;!&ndash;                  <button mat-button class="animated"  *ngIf="info && info.oss_info && info.oss_info.current_oss && info.oss_info.current_oss.length>0" (click)="scanBarcode()" matTooltip="Сканировать QR-код решения">&ndash;&gt;-->
<!--                  &lt;!&ndash;                    <mat-icon>qr_code</mat-icon>&ndash;&gt;-->
<!--                  &lt;!&ndash;                  </button>&ndash;&gt;-->

<!--                </mat-menu>-->
<!--                <mat-menu #menu2="matMenu" >-->
<!--                  <button mat-menu-item (click)="navigateToOssLink()" color="primary" style="margin-top: -7px;" ><mat-icon>launch</mat-icon> Перейти ..</button>-->
<!--                  <button mat-menu-item (click)="showQr=true;" color="primary" style="margin-top: -7px;" ><mat-icon>qr_code</mat-icon>  показать QR-код</button>-->
<!--                  <input id="copyToClipboardElement"-->
<!--                         (click)="$event.preventDefault(); $event.stopPropagation();"-->
<!--                         (mouseup)="$event.preventDefault(); $event.stopPropagation();"-->
<!--                         readonly-->
<!--                          style="min-width: 100px; max-width: 300px; margin-left: 10px; margin-right: 10px" value="{{current_link}}">-->
<!--                </mat-menu>-->
              </div>
              <div class="oss-messages-area">

                <div class="prev-message-button">
                  <button mat-button [disabled]="oss.note_index<=0" (click)="oss.note_index=oss.note_index - 1">
                    <mat-icon>arrow_back_ios</mat-icon>
                  </button>
                </div>
                <div class="content-message">
                  <div *ngIf="oss.note_index < oss.notes.length">
                    <span class="note-time">{{oss.notes[oss.note_index].date | date: 'HH:mm dd/MM/yyyy'}}</span>
                    <span class="note-status status" [style.color]="oss.notes[oss.note_index].status__icon_color">
                      <mat-icon style="">{{oss.notes[oss.note_index].status__icon}}</mat-icon>
                      <span class="status-title">{{oss.notes[oss.note_index].status__title}}</span>
                    </span>
                    <span class="note-text status-title">{{oss.notes[oss.note_index].text}}</span>
                    <span *ngIf="oss.notes[oss.note_index].files__id && oss.notes[oss.note_index].files__id.length > 0">
                      <button mat-icon-button color="primary" (click)="showFilesIds(oss.notes[oss.note_index].files__id)">
                        <mat-icon [matBadge]="oss.notes[oss.note_index].files__id.length" style="font-size: x-large" matBadgeSize="small" matBadgeColor="primary"  >attach_file</mat-icon>
                      </button>
                    </span>
                  </div>
                </div>
                <div class="next-message-button">
                  <button mat-button [disabled]="oss.note_index>=oss.notes.length-1"
                          (click)="oss.note_index=oss.note_index + 1">
                    <mat-icon>arrow_forward_ios</mat-icon>
                  </button>
                </div>
              </div>

              <div class="new-message-editor" style="margin: 0px 62px 0 62px; width: calc(100% - 86px);" *ngIf="oss.newNote">
                <div class="new-message-editor-message">
                  <mat-form-field class="full-width">
                    <input matInput style="" [(ngModel)]="oss.newNote[4]" name="new-note-editor"/>
                  </mat-form-field>
                  <div class="message-files-area" >
                    <ng-container>
                      <div *ngFor="let editFile of oss.newNote[5]; let i= index " class="item-message-file">
                        <span *ngIf="editFile[0] == 0">
                          <a routerLink=".">{{editFile[1]}}</a>
                          <button mat-icon-button color="accent" (click)="$event.stopPropagation(); deleteAttachment(i, oss.newNote[5])">
                            <mat-icon>cancel</mat-icon>
                          </button>
                        </span>
                        <mat-icon *ngIf="editFile.length > 2 && editFile[2].length == 0">play_for_work</mat-icon>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div class="new-message-editor-buttons">
                  <div class="new-message-editor-button-attfile">
                    <button mat-icon-button matSuffix color="primary" (click)="getElementById('ossfileinput'+oss.id).click()">
                      <mat-icon>attach_file</mat-icon>
                    </button>
                  </div>
                  <div class="new-message-editor-button-send">
                    <button mat-icon-button matSuffix color="primary" (click)="sendOssMessage($event, oss)">
                      <mat-icon style="font-size: 32px;">send</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <hr/>
            </div>
          </ng-container>
          <div style="display: inline-flex; width: 100%; flex-wrap: wrap;">
            <div *ngFor="let status of main.statuses">
              <button mat-raised-button
                      class="text-left" [style.color]="status.icon_color"
                      (click)="setStatus(0, status.key, 0, human.human_id, status.id)">
                <span style="margin-left: 0;">
                <span class="vertical-middle"> {{status.button_title}}</span></span>
              </button>
            </div>
          </div>
        </ng-container>


      </div>
    </div>
    <button mat-raised-button [routerLink]="['/human']" style="margin-top: 10px"
            [queryParams]="{ human_id: human.human_id }" (click)="dialogRef.close()">Подробнее...
    </button>
    <button mat-raised-button style="margin-top: 10px"
            (click)="dialogRef.close()">Закрыть
    </button>
  </div>
</div>


